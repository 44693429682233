/* eslint-disable no-nested-ternary */
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useState } from 'react';

// Icons
import { DeleteOutlined } from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import {
  GLOBAL_PAGINATION_LIMIT,
  WHATSAPP_TEMPLATE_SELECT
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import {
  addWhatsappTemplate,
  deleteWhatsappTemplate,
  getAllWhatsappTemplates
} from './whatsapp-templates-reporitory';

function WhatsappTemplates() {
  const { required } = formValidatorRules;
  const [addressForm] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [wpTemplates, setWpTemplates] = useState([]);
  const [paginationProp, setPaginationProp] = useState(false);
  const [addAddressCustomer, setAddAddressCustomer] = useState(false);
  const [isSavingAddress, setIsSavingAddress] = useState(false);
  const [pageNum, setPageNum] = useState(0);

  const selectOptions = [
    {
      value: WHATSAPP_TEMPLATE_SELECT.MARKETING,
      label: WHATSAPP_TEMPLATE_SELECT.MARKETING
    },
    {
      value: WHATSAPP_TEMPLATE_SELECT.UTILITY,
      label: WHATSAPP_TEMPLATE_SELECT.UTILITY
    }
  ];

  const fetchWhatsappTemplates = async ({ currentSkip }) => {
    const filters = {
      limit: limit,
      skip: currentSkip
    };
    const payload = {
      type: null,
      filters
    };
    try {
      //
      const response = await getAllWhatsappTemplates(payload);
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setWpTemplates(response.templates.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchWhatsappTemplates({
      currentSkip: pageNum
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CREATE NEW MODAL
  const handleCancelAddress = () => {
    addressForm.resetFields();
    setAddAddressCustomer(false);
  };

  const columns = [
    {
      title: 'Sr.No',
      dataIndex: 'id',
      key: '1',
      render: (value, record, idx) => {
        return idx + 1;
      }
    },
    {
      title: 'Template Name',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'Template id',
      dataIndex: 'templateId',
      key: '3'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: '4'
    },
    {
      title: '',
      dataIndex: 'id',
      key: '5',
      render: (value) => {
        return (
          <Popconfirm
            title="Delete whatsapp template"
            description="Are you sure want to delete this template?"
            onConfirm={async () => {
              await deleteWhatsappTemplate(value);
              fetchWhatsappTemplates({
                currentSkip: pageNum
              });
            }}
          >
            <DeleteOutlined
              style={{
                color: '#eb5757',
                cursor: 'pointer',
                fontSize: '20px'
              }}
            />
          </Popconfirm>
        );
      }
    }
  ];

  const addNewAddress = async (formValue) => {
    const payload = {
      name: formValue.templateName,
      templateId: formValue.templateId,
      type: formValue.templateType
    };
    try {
      setIsSavingAddress(true);
      const response = await addWhatsappTemplate(payload);
      if (response) {
        const { template } = response;
        if (wpTemplates.length < limit) {
          // Add as the last element on the current page
          setWpTemplates((prevData) => [...prevData, template]);
        }
        // Update pagination (always increment total count)
        const updatedPagination = {
          ...paginationProp,
          total: paginationProp.total + 1
        };
        setPaginationProp(updatedPagination);
        setIsSavingAddress(false);
        handleCancelAddress();
      } else {
        setIsSavingAddress(false);
        throw Error();
      }
    } catch (error) {
      setIsSavingAddress(false);
      message.error('Failed to add template');
    }
    handleCancelAddress();
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Whatsapp Templates
            </Typography.Text>
          </Space>
          <Button
            onClick={() => {
              setAddAddressCustomer(true);
            }}
            className="create-btn"
          >
            + Create New
          </Button>
        </div>
      </Header>
      {/* ADD NEW ADDRESS MODAL */}
      <Modal
        open={addAddressCustomer}
        onCancel={handleCancelAddress}
        className="create-patient-modal addressModal"
        centered
        title="Whatsapp template"
      >
        <Divider />
        <Form layout="vertical" form={addressForm} onFinish={addNewAddress}>
          <div className="patient-container">
            <Row className="row-one">
              <Col span={12} className="left">
                <Form.Item
                  rules={[required]}
                  name="templateName"
                  label="Template name"
                >
                  <Input type="text" placeholder="Enter template name" />
                </Form.Item>
              </Col>
              <Col span={12} className="right">
                <Form.Item
                  rules={[required]}
                  name="templateId"
                  label="Template ID"
                >
                  <Input type="text" placeholder="Enter template id" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="row-one">
              <Col span={12} className="left">
                <Form.Item
                  rules={[required]}
                  name="templateType"
                  label="Template type"
                >
                  <Select
                    options={selectOptions}
                    placeholder="Select template type"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="create-wrapper">
            <Button
              loading={isSavingAddress}
              htmlType="submit"
              className="add-patient-btn"
            >
              Add Template
            </Button>
          </div>
        </Form>
      </Modal>

      <div className="equipments-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowKey={(record) => record.id}
            columns={columns}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={wpTemplates}
            onChange={(onChange) => {
              setLoadingData(true);
              setPageNum((onChange.current - 1) * limit);
              fetchWhatsappTemplates({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default WhatsappTemplates;

import {
  Button,
  Divider,
  Empty,
  Modal,
  Radio,
  Space,
  Tag,
  Tooltip,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import { getDatabase, onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';

import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT,
  ROUTES,
  WHATSAPP_TEMPLATE_SELECT
} from '../../common/constants';
import firebaseConfig from '../../common/firebase-config';
import { testTitleEllipsis } from '../../common/utils';
import { getAllWhatsappTemplates } from '../whatsapp-templates/whatsapp-templates-reporitory';
import {
  getAllCustomerCarts,
  sendCartTemplateMessage,
  sendCartTemplateMessageToAll
} from './added-carts-repository';
import './added-carts.less';

function AddedCarts() {
  const limit = GLOBAL_PAGINATION_LIMIT;
  const navigate = useNavigate();
  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [customerCarts, setCustomerCarts] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [query, setQuery] = useState('');
  const [templates, setTemplates] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [messageModal, setMessageModal] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const [isSentToAll, setIsSentToAll] = useState(false);
  const [snapshotData, setSnapshotData] = useState();

  const fetchCustomersCarts = async ({ currentQuery, currentSkip }) => {
    try {
      // setCurrentGlobalSkip(currentSkip);
      const response = await getAllCustomerCarts({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setCustomerCarts(response.carts.slice());
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
    }
  };

  const fetchCartTemplates = async () => {
    const filters = {
      limit: 1000000,
      skip: 0
    };
    const payload = {
      type: WHATSAPP_TEMPLATE_SELECT.MARKETING,
      filters
    };
    try {
      const response = await getAllWhatsappTemplates(payload);
      if (response) {
        setTemplates(response.templates);
      }
    } catch (error) {
      message.error('Failed to fetch templates');
    }
  };

  const triggerRealtimeLoading = () => {
    const db = getDatabase(firebaseConfig);
    const dbRef = ref(db, '11za');
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      setSnapshotData(data);
    });
  };

  useEffect(() => {
    fetchCustomersCarts({
      currentQuery: '',
      currentSkip: 0
    });
    fetchCartTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    triggerRealtimeLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelModal = () => {
    setCustomerId('');
    setSelectedObject(null);
    setIsSentToAll(false);
    setMessageModal(false);
  };

  const onSendMessage = async () => {
    if (!selectedObject) {
      message.error('Please select a template');
      return;
    }

    try {
      setIsButtonLoading(true);
      if (isSentToAll) {
        triggerRealtimeLoading();
        const payload = {
          templateId: selectedObject.templateId
        };
        setMessageModal(false);
        await sendCartTemplateMessageToAll(payload);
      } else {
        const payload = {
          customerId,
          templateId: selectedObject.templateId
        };
        // setMessageModal(false);
        await sendCartTemplateMessage(payload);
      }
      setIsButtonLoading(false);
      handleCancelModal();
      message.success('Message Sent');
    } catch (error) {
      setIsButtonLoading(false);
      handleCancelModal();
      message.error('Failed to send the message');
    }
  };

  const sentSingleMessage = (data) => {
    const { id } = data;
    setCustomerId(id);
    setMessageModal(true);
  };

  const sentBulkMessages = () => {
    setIsSentToAll(true);
    setMessageModal(true);
  };

  // COLUMNS
  const columns = [
    {
      title: 'Name',
      dataIndex: 'customer',
      key: 'id',
      render: (value) => {
        const { name } = value;
        // eslint-disable-next-line no-lone-blocks
        {
          if (name.length < 20) {
            return (
              <Typography
                className="clickable-title"
                onClick={() => {
                  const url = `${ROUTES.CUSTOMER_DETAIL}?customerId=${value.id}`;
                  navigate(url);
                }}
              >
                {name}
              </Typography>
            );
          }

          return (
            <Typography
              className="clickable-title"
              onClick={() => {
                const url = `${ROUTES.CUSTOMER_DETAIL}?customerId=${value.id}`;
                navigate(url);
              }}
            >
              <Tooltip title={name} color="#232e78">
                {name.slice(0, 20)}...
              </Tooltip>
            </Typography>
          );
        }
      }
    },
    {
      title: 'Mobile number',
      dataIndex: 'customer',
      key: 'customer',
      render: (value) => {
        const { countryCode, mobileNumber } = value;
        return (
          <p>
            {countryCode}-{mobileNumber}
          </p>
        );
      }
    },
    {
      title: 'Tests',
      dataIndex: 'tests',
      key: 'tests',
      render: (value) => {
        const total = 2;
        const tests = [];
        const testTitle = [];
        for (let i = 0; i < value.length; i += 1) {
          tests.push(
            <Tooltip title={value[i].title}>
              <Tag className="test-tags">
                {testTitleEllipsis(value[i].title, 30)}
              </Tag>
            </Tooltip>
          );
          testTitle.push({
            id: value[i].id,
            title: value[i].title
          });
        }
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {tests.length > total ? (
              <Space size={0}>
                {tests.slice(0, 2).map((test, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={index}>{test}</span>
                ))}
                <Tooltip title={testTitle.map((item) => item.title).join(', ')}>
                  <Tag color="blue" className="text-tags">
                    +{tests.length - total}
                  </Tag>
                </Tooltip>
              </Space>
            ) : (
              <Space size={0}>
                {tests.map((test, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={index}>{test}</span>
                ))}
              </Space>
            )}
          </>
        );
      }
    },
    {
      title: 'Total amount',
      dataIndex: 'subTotal',
      key: '6',
      render: (value) => {
        return `₹ ${value}`;
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: '6',
      render: (value) => {
        return dayjs(value).format('DD/MM/YYYY  hh:mm a');
      }
    },
    {
      title: 'Action',
      dataIndex: 'customer',
      key: 'id',
      render: (value) => {
        return (
          <AiOutlineSend
            onClick={() => {
              sentSingleMessage(value);
            }}
            style={{
              cursor: 'pointer'
            }}
          />
        );
      }
    }
  ];

  const progressCounter = () => {
    return `${snapshotData?.completed}/${snapshotData?.total}`;
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Added Carts
            </Typography.Text>

            <SearchComponent
              name="Customers"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchCustomersCarts({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button
            onClick={sentBulkMessages}
            loading={snapshotData?.inProgress}
            className="create-btn"
          >
            {snapshotData?.inProgress ? progressCounter() : '  Sent to all'}
          </Button>
        </div>
      </Header>
      <div className="main-content customers-content">
        <div className="customers-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={customerCarts}
              onChange={(onChange) => {
                setLoadingData(true);
                fetchCustomersCarts({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
      {/* MODAL CONTENT */}
      <Modal
        open={messageModal}
        onCancel={handleCancelModal}
        className="cart-template-modal"
        footer={null}
        centered
        title="Send message"
      >
        <Divider />
        <div className="carts-modal">
          <div className="radio-content">
            <Radio.Group
              className="cart-radio-group"
              value={selectedObject?.id}
              onChange={(e) => {
                const object = templates.find(
                  (item) => item.id === e.target.value
                );
                setSelectedObject(object);
              }}
            >
              {templates.length > 0 ? (
                templates.map((item) => {
                  return (
                    <Radio key={item.id} value={item.id}>
                      {item.name}
                    </Radio>
                  );
                })
              ) : (
                <Empty />
              )}
            </Radio.Group>
          </div>
          {/* {selectedObject && (
            <div className="cart-radio-content">
              <span className="template-content">{selectedObject.body}</span>
            </div>
          )} */}
        </div>
        <Divider />
        <div className="added-carts-align">
          <Button
            loading={isButtonLoading}
            onClick={onSendMessage}
            htmlType="submit"
            className="create-btn-modal banner-btn"
          >
            Send
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AddedCarts;

import { gql } from '@apollo/client';

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

export const UPDATE_AFFILIATE_LINK = gql`
  mutation UpdateAffiliateLink($affiliateId: String) {
    updateAffiliateLink(affiliateId: $affiliateId)
  }
`;

import { gql } from '@apollo/client';

export const GET_ALL_WHATSAPP_TEMPLATES = gql`
  query GetWhatsAppCampaignTemplates(
    $type: String
    $filters: GetDeepLinksInput
  ) {
    getWhatsAppCampaignTemplates(type: $type, filters: $filters) {
      total
      templates {
        id
        templateId
        name
        type
      }
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

import { message } from 'antd';
import client from '../../apollo';
import {
  ADD_NEW_WHATSAPP_TEMPLATE,
  DELETE_WHATSAPP_TEMPLATE
} from './graphql/mutation';
import { GET_ALL_WHATSAPP_TEMPLATES } from './graphql/query';

export const getAllWhatsappTemplates = async (data) => {
  const { type, filters } = data;
  try {
    const response = await client.query({
      query: GET_ALL_WHATSAPP_TEMPLATES,
      fetchPolicy: 'network-only',
      variables: {
        type,
        filters
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getWhatsAppCampaignTemplates;
  } catch (error) {
    message.error(error.message);
    return [];
  }
};

export const addWhatsappTemplate = async (data) => {
  try {
    const response = await client.mutate({
      mutation: ADD_NEW_WHATSAPP_TEMPLATE,
      fetchPolicy: 'network-only',
      variables: {
        data
      }
    });

    return response?.data?.addWhatsappTemplateDetails;
  } catch (error) {
    message.error(error.message);
    // eslint-disable-next-line no-console
    return false;
  }
};

export const deleteWhatsappTemplate = async (deleteTemplateMessageId) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_WHATSAPP_TEMPLATE,
      fetchPolicy: 'network-only',
      variables: {
        deleteTemplateMessageId
      }
    });
    // eslint-disable-next-line no-console
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const sample = () => {};

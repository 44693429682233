/* eslint-disable no-console */
import {
  Button,
  Divider,
  Empty,
  message,
  Modal,
  Radio,
  Select,
  Tooltip,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { AiOutlineExclamationCircle, AiOutlineSend } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
import {
  getPayVia,
  getSourceTag,
  getTag,
  normalizeEnums
} from '../../../common/components/common-components';
import {
  defaultDateFormat,
  GLOBAL_PAGINATION_LIMIT,
  ROUTES
} from '../../../common/constants';
import {
  getCancelledOrders,
  sendMessageToAdminPlacedOrderCustomer
} from '../reports-repository';

function CancelledOrders({
  query,
  startDateVal,
  endDateVal,
  doctorList,
  templates
}) {
  const navigate = useNavigate();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const format = defaultDateFormat;
  const { pageNo } = useParams();
  const currentPage = parseInt(pageNo, 10) || 1;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [historyReports, setHistoryReports] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  // const [doctorList, setDoctorList] = useState([]);

  // MESSAGE MODAL
  // MESSAGE MODAL
  const [messageModal, setMessageModal] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [orderId, setOrderId] = useState('');

  // FETCHING ACTIVE REPORTS

  const fetchActiveReports = async ({ currentSkip, sDate, eDate }) => {
    setLoadingData(true);
    try {
      //
      const response = await getCancelledOrders({
        skip: currentSkip,
        limit: limit,
        query: query,
        startDate: sDate,
        endDate: eDate
      });

      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setHistoryReports(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchActiveReports({
      currentSkip: (currentPage - 1) * limit,
      sDate: startDateVal,
      eDate: endDateVal
    });
    // fetchDoctorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, currentPage, startDateVal, endDateVal]);

  const handlePageChange = (page) => {
    // Use the navigate function to update the URL
    navigate(`/reports/cancelled/${page}`);
  };

  const selectRow = (record) => {
    const url = `${ROUTES.REPORT_DETAIL}?orderId=${record.id}`;
    navigate(url);
  };

  // SEND MESSAGE

  const handleCancelModal = () => {
    setOrderId('');
    setSelectedObject(null);
    setMessageModal(false);
  };

  const sentSingleMessage = (data) => {
    const { id } = data;
    setOrderId(id);
    setMessageModal(true);
  };

  const onSendMessage = async () => {
    if (!selectedObject) {
      message.error('Please select a template');
      return;
    }

    const payload = {
      orderId,
      templateId: selectedObject.name
    };
    try {
      setIsButtonLoading(true);
      await sendMessageToAdminPlacedOrderCustomer(payload);
      setIsButtonLoading(false);
      handleCancelModal();
      message.success('Message Sent');
    } catch (error) {
      setIsButtonLoading(false);
      handleCancelModal();
      message.error('Failed to send the message');
    }
  };

  const columns = [
    {
      title: 'Order#',
      dataIndex: 'orderId',
      key: '1',
      render: (value, record) => {
        const { source } = record;
        return (
          <div>
            <Typography
              className="clickable-title"
              onClick={() => {
                selectRow(record);
              }}
            >
              {value}
            </Typography>
            {getSourceTag(source)}
          </div>
        );
      }
    },
    {
      title: 'Patient',
      dataIndex: 'patient',
      key: '2',
      render: (value) => {
        return value.name;
      }
    },
    {
      title: 'Booked on',
      dataIndex: 'scheduledAt',
      key: '3',
      render: (value) => {
        if (!value) return '';
        return dayjs(value).format(format);
      }
    },

    {
      title: 'Scheduled at',
      dataIndex: 'scheduledAt',
      key: '5',
      render: (value) => {
        if (!value) return '';
        return dayjs(value).format('hh:mm a');
      }
    },
    {
      title: 'Total amount',
      dataIndex: 'subTotal',
      key: '6',
      render: (value) => {
        return `₹ ${value}`;
      }
    },
    {
      title: 'Payment mode',
      dataIndex: 'paymentVia',
      key: '7',
      render: (value, record) => {
        return (
          <div>
            {getPayVia(value)} {getTag(record.paymentStatus)}
          </div>
        );
      }
    },
    {
      title: 'Booked Status',
      dataIndex: 'orderStatus',
      key: '8',
      align: 'right',
      render: (value) => {
        return normalizeEnums(value);
      }
    },
    {
      title: 'Order Transit Status',
      dataIndex: 'orderTransitStatus',
      key: '9',
      align: 'right',
      render: (value) => {
        return normalizeEnums(value);
      }
    },
    {
      title: 'Cancelled by',
      dataIndex: 'cancelledBy',
      key: '10',
      align: 'center',
      render: (value) => {
        if (value === null) {
          return '-';
        }
        return value.userRole;
      }
    },
    {
      title: 'Order OTP',
      dataIndex: 'otpForTest',
      key: '10'
    },
    {
      title: 'Sample Submitted To',
      dataIndex: 'sampleSubmitTo',
      key: '11',
      render: (value) => {
        return (
          <Select
            className="sample-selector"
            placeholder="Select Lab"
            value={value?.displayName}
            disabled
          />
        );
      }
    },
    {
      title: 'Link Doctor',
      dataIndex: 'doctorAssigned',
      key: '11',
      render: (value, record) => {
        return (
          <Select
            disabled
            className="sample-selector"
            placeholder="Select Doctor"
            value={value}
            options={doctorList.map((doc) => {
              return {
                value: doc.id,
                label: doc.name,
                orderId: record.id
              };
            })}
          />
        );
      }
    },
    {
      title: '',
      dataIndex: 'reason',
      key: '11',
      render: (value, record) => {
        const { orderStatus } = record;
        if (orderStatus === 'CANCELLED')
          return (
            <Tooltip title={value} color="#232e78">
              <AiOutlineExclamationCircle
                color="red"
                style={{
                  cursor: 'pointer'
                }}
                size={20}
              />
            </Tooltip>
          );
      }
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => {
        const { source } = record;
        if (source === 'ADMIN') {
          return (
            <AiOutlineSend
              onClick={() => {
                sentSingleMessage(record);
              }}
              style={{
                cursor: 'pointer'
              }}
            />
          );
        }
      }
    }
  ];
  return (
    <div className="main-content test-categories-content">
      <Modal
        open={messageModal}
        onCancel={handleCancelModal}
        className="cart-template-modal"
        footer={null}
        centered
        title="Send message"
      >
        <Divider />
        <div className="carts-modal">
          <div className="radio-content">
            <Radio.Group
              className="cart-radio-group"
              value={selectedObject?.id}
              onChange={(e) => {
                const object = templates.find(
                  (item) => item.id === e.target.value
                );
                setSelectedObject(object);
              }}
            >
              {templates.length > 0 ? (
                templates.map((item) => {
                  return (
                    <Radio key={item.id} value={item.id}>
                      {item.name}
                    </Radio>
                  );
                })
              ) : (
                <Empty />
              )}
            </Radio.Group>
          </div>
          {selectedObject && (
            <div className="cart-radio-content">
              <span className="template-content">{selectedObject.body}</span>
            </div>
          )}
        </div>
        <Divider />
        <div className="added-carts-align">
          <Button
            loading={isButtonLoading}
            onClick={onSendMessage}
            htmlType="submit"
            className="create-btn-modal banner-btn"
          >
            Send
          </Button>
        </div>
      </Modal>
      {loading ? (
        <LoaderComponent />
      ) : (
        <CommonTable
          columns={columns}
          rowKey={(record) => record.id}
          loadingData={loadingData}
          paginationConfig={paginationProp}
          data={historyReports}
          onChange={(onChange) => {
            // eslint-disable-next-line no-console
            setLoadingData(true);
            fetchActiveReports({
              // currentQuery: query,
              currentSkip: (onChange.current - 1) * limit
            });
            handlePageChange(onChange.current);
          }}
        />
      )}
    </div>
  );
}

export default CancelledOrders;

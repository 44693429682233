/* eslint-disable no-nested-ternary */
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import React, { useEffect, useState } from 'react';

// Icons
import { DeleteOutlined } from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import { AiOutlineShareAlt } from 'react-icons/ai';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchTests from '../../common/components/TestsSearch';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import {
  clearSearchField,
  formValidatorRules,
  testTitleEllipsis
} from '../../common/utils';
import {
  createNewUniversalCart,
  deleteUniversalCart,
  getAllUniversalCarts
} from './universal-carts-reporitory';

function UniversalCarts() {
  const { required } = formValidatorRules;
  const [addressForm] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [allCarts, setAllCarts] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [addCartModal, setAddCartModal] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [savingData, setSavingData] = useState(false);

  // TESTS STATES

  const [selectedTests, setSelectedTests] = useState([]);
  const [resetTrigger, setResetTrigger] = useState(0);

  const fetchAllCarts = async ({ currentSkip }) => {
    try {
      //
      const response = await getAllUniversalCarts({
        skip: currentSkip,
        limit: limit
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setAllCarts(response.universalCart.slice());
    } catch (error) {
      setLoading(false);
      setLoadingData(false);
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchAllCarts({
      currentSkip: pageNum
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelCreateCard = () => {
    addressForm.resetFields();
    clearSearchField('Tests');
    setResetTrigger((prev) => prev + 1);
    setSelectedTests([]);
    setAddCartModal(false);
  };

  const openInNewTab = (data) => {
    const { dynamicLink } = data;
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(dynamicLink).then(() => {
      message.success('Link copied to clipboard');
    });
  };

  const columns = [
    {
      title: 'Sr.No',
      dataIndex: 'id',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'Cart Name',
      dataIndex: 'cartName',
      key: '2'
    },
    {
      title: 'Tests',
      dataIndex: 'tests',
      key: 'tests',
      render: (value) => {
        const total = 2;
        const tests = [];
        const testTitle = [];
        for (let i = 0; i < value.length; i += 1) {
          tests.push(
            <Tooltip title={value[i].title}>
              <Tag className="test-tags">
                {testTitleEllipsis(value[i].title, 30)}
              </Tag>
            </Tooltip>
          );
          testTitle.push({
            id: value[i].id,
            title: value[i].title
          });
        }
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {tests.length > total ? (
              <Space size={0}>
                {tests.slice(0, 2).map((test, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={index}>{test}</span>
                ))}
                <Tooltip title={testTitle.map((item) => item.title).join(', ')}>
                  <Tag color="blue" className="text-tags">
                    +{tests.length - total}
                  </Tag>
                </Tooltip>
              </Space>
            ) : (
              <Space size={0}>
                {tests.map((test, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={index}>{test}</span>
                ))}
              </Space>
            )}
          </>
        );
      }
    },
    {
      title: 'Click count',
      dataIndex: 'claimed',
      key: '3'
    },
    {
      title: 'Placed count',
      dataIndex: 'placed',
      key: '4'
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: '5',
      render: (value, record) => {
        return (
          <div className="btn-space">
            <Popconfirm
              title="Delete Cart"
              description="Are you sure want to delete this cart?"
              onConfirm={async () => {
                await deleteUniversalCart(value);
                fetchAllCarts({
                  currentSkip: pageNum
                });
              }}
            >
              <DeleteOutlined
                style={{
                  color: '#eb5757',
                  cursor: 'pointer',
                  fontSize: '20px'
                }}
              />
            </Popconfirm>
            <AiOutlineShareAlt
              style={{
                cursor: 'pointer'
              }}
              size={20}
              onClick={() => {
                openInNewTab(record);
              }}
            />
          </div>
        );
      }
    }
  ];

  const createNewCart = async (formValues) => {
    if (!selectedTests.length) {
      message.error('Please select at least one test');
      return;
    }
    const testIds = selectedTests.map((data) => {
      return data.id;
    });
    const payload = {
      cartName: formValues.cartName,
      tests: testIds
    };

    try {
      setSavingData(true);
      // Call the mutation function to create a deep link
      const newCart = await createNewUniversalCart(payload);
      if (!newCart) {
        throw new Error('Failed to create a new deep link');
      }
      if (
        allCarts.length === 0 ||
        allCarts.length < limit ||
        allCarts[allCarts.length - 1].serialNumber + 1 === allCarts.serialNumber
      ) {
        const updatedData = allCarts.slice();
        updatedData.push(newCart);
        setAllCarts(updatedData.slice());
      }
      handleCancelCreateCard(); // Close the modal
      setSavingData(false);
    } catch (error) {
      message.error('Failed to add deep link');
      setSavingData(false);
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Universal Carts
            </Typography.Text>
          </Space>
          <Button
            onClick={() => {
              setAddCartModal(true);
            }}
            className="create-btn"
          >
            + Create New
          </Button>
        </div>
      </Header>
      {/* ADD NEW ADDRESS MODAL */}
      <Modal
        open={addCartModal}
        onCancel={handleCancelCreateCard}
        className="create-test-modal"
        centered
        title="Add tests"
      >
        <Divider />
        <Form layout="vertical" form={addressForm} onFinish={createNewCart}>
          <Form.Item
            rules={[required]}
            name="cartName"
            className="input-title"
            label="Cart Name"
          >
            <Input type="text" placeholder="Cart name" />
          </Form.Item>
          <SearchTests
            onSelectedTestsChange={setSelectedTests}
            resetTrigger={resetTrigger}
          />

          <Divider />
          <Button
            loading={savingData}
            htmlType="submit"
            className="create-btn offer-save-btn"
          >
            Create Cart
          </Button>
        </Form>
      </Modal>

      <div className="equipments-table">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            rowKey={(record) => record.id}
            columns={columns}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={allCarts}
            onChange={(onChange) => {
              setLoadingData(true);
              setPageNum((onChange.current - 1) * limit);
              fetchAllCarts({
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default UniversalCarts;

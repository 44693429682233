/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  BOOKED_CUSTOMERS: '/repeated-customers',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  CUSTOMERS: '/customers/:pageNo',
  CUSTOMER_DETAIL: '/customers/edit',
  CUSTOMER_ORDER_HISTORY: '/customers/orders/edit',
  CUSTOMER_APP_NOTIFICATIONS: '/customers/notifications/edit',
  REPORTS: '/reports/:reportType/:pageNo',
  REPORT_DETAIL: '/reports/edit',
  ORDER_TRANSACTION: '/reports/transactions/edit',
  OFFERS: '/offers',
  OFFERS_DETAILS: '/offers/:oID',
  USERS: '/users/',
  TESTIMONIALS: '/testimonials',
  TIME_SHEET: '/time-sheet',
  PRESCRIPTIONS: '/prescriptions',
  MESSAGES: '/messages',
  ADDED_CARTS: 'added-carts',
  MESSAGE_DETAILS: '/messages/edit',
  CONFIGURATIONS: '/configurations',
  LABS: '/labs',
  TEMPLATE_ADDRESS: '/template-address',
  TEMPLATE_WHATSAPP: '/template-whatsapp',
  UNIVERSAL_CARTS: '/universal-carts',
  DISEASE_CATEGORIES: '/disease-categories',
  DISEASES: '/diseases/:pageNo',
  DISEASE_QUESTIONS: '/diseases/:pageNo/:id',
  DOCTORS: '/doctors',
  DOCTOR_DETAILS: '/doctors/:id',
  DOCTOR_REPORTS: '/doctors/:id/more-details',
  DEEP_LINK_MONITOR: '/deep-link-monitor',
  AFFILIATE: '/affiliate',

  // HOME ROUTES
  BANNERS: '/home/banners',
  TIMELY_OFFERS: '/home/timely-offers',
  RULE_BASED: '/home/rule-based',
  PIN_CODES: '/home/pin-codes',
  CTA_BANNERS: '/home/cta-banners',

  // TESTS ROUTES
  ALL_TESTS: '/test/all-tests',
  TESTED_CUSTOMERS: '/test/all-tests/:cID',
  CATEGORIES: '/test/categories',
  EQUIPMENTS: '/test/equipments',
  SAMPLES: '/test/samples',
  ADDONS: '/test/addons',

  // LAB ROUTES
  LAB_REPORTS: '/lab-reports'
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USERS_MANAGEMENT: 'User Management'
};

export const GLOBAL_PAGINATION_LIMIT = 10;

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';

/* Date and time */
export const defaultDateFormat = 'DD/MM/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const FILETYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const FILE_EXTENSION = '.xlsx';

export const SAMPLE_ID = {
  BLOOD: '64a5427fd9e542a206debb78',
  URINE: '64a54289d9e542a206debb81',
  STOOL: '64a54293d9e542a206debb8a'
};

export const SAMPLE_ID_DEV = {
  BLOOD: '64a28001b53e4e9fadc5ba73',
  URINE: '64a2800cb53e4e9fadc5ba7c',
  STOOL: '64a28015b53e4e9fadc5ba85'
};

export const EQUIPMENTS_ID = {
  EDTA_TUBE: '64a542fbd9e542a206debbab',
  PLANE_TUBE: '64a54313d9e542a206debbb7',
  FLUORIDE_TUBE: '64a54325d9e542a206debbc4',
  CITRATE_TUBE: '64a54344d9e542a206debbd2',
  HEPARIN: '64a5435bd9e542a206debbe7',
  URINE_CONTAINER: '64a54449d9e542a206debc4a',
  SYRING_2ML: '64a7d08dae252b8d08bc3fa0',
  SYRING_5ML: '64a7d09fae252b8d08bc3fb2',
  SYRING_10ML: '64a7d0aeae252b8d08bc3fc5',
  GLOVES: '64a92e7bae252b8d08bc52f3'
};

export const EQUIPMENTS_ID_DEV = {
  EDTA_TUBE: '64a280cbb53e4e9fadc5bab4',
  PLANE_TUBE: '64a280d9b53e4e9fadc5bac0',
  FLUORIDE_TUBE: '64a280ebb53e4e9fadc5bacd',
  CITRATE_TUBE: '64a2815bb53e4e9fadc5baeb',
  HEPARIN: '64a2817fb53e4e9fadc5bb00',
  URINE_CONTAINER: '64a28193b53e4e9fadc5bb14',
  SYRING_2ML: '64a281c5b53e4e9fadc5bb27',
  SYRING_5ML: '64a281d7b53e4e9fadc5bb39',
  SYRING_10ML: '64a281fab53e4e9fadc5bb52',
  GLOVES: '64a28216b53e4e9fadc5bb66'
};

// SLOT ENUM
export const SLOT_ENUM = {
  MORNING: 'MORNING',
  NOON: 'NOON',
  EVENING: 'EVENING'
};

// DEBOUNCE TIME IN SEARCH BAR
export const DEBOUNCE_TIME = 200;

export const MOBILE_REGEX = /^[0-9]+$/;
export const normalDateFormat = 'DD/MM/YYYY';

export const ORDER_TRANSIT_STATUS = [
  { status: 'PENDING', isDisabled: true },
  { status: 'COLLECTED', isDisabled: true },
  { status: 'SEND_TO_LAB', isDisabled: false },
  { status: 'REPORT_UPLOADED', isDisabled: true },
  { status: 'REPORT_PUBLISHED', isDisabled: true },
  { status: 'DELIVERED', isDisabled: true }
];

export const WHATSAPP_TEMPLATE_SELECT = {
  UTILITY: 'UTILITY',
  MARKETING: 'MARKETING'
};

export const ORDER_TEMPLATE_CONSTANTS = [
  'order_placed',
  'order_cancelled',
  'sample_collection_rescheduled',
  'report_ready',
  'refund_initiated',
  'payment_link'
];

import { gql } from '@apollo/client';

export const ADD_NEW_WHATSAPP_TEMPLATE = gql`
  mutation AddWhatsappTemplateDetails($data: WhatsappTemplateInput) {
    addWhatsappTemplateDetails(data: $data) {
      template {
        id
        templateId
        name
        type
      }
    }
  }
`;

export const DELETE_WHATSAPP_TEMPLATE = gql`
  mutation DeleteTemplateMessage($deleteTemplateMessageId: ID!) {
    deleteTemplateMessage(id: $deleteTemplateMessageId)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

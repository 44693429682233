import { Button, Empty, message, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getLiveTestsAndPackages } from '../../modules/tests/test-repository';
import { DEBOUNCE_TIME } from '../constants';
import SearchComponent from './SearchDebounce';

function SearchTests({ onSelectedTestsChange, resetTrigger }) {
  const [fetchedTests, setFetchedTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [isFetchingTests, setIsFetchingTests] = useState(false);
  const [showingTestResults, setShowingTestResults] = useState(false);

  useEffect(() => {
    // Reset state whenever resetTrigger changes
    setFetchedTests([]);
    setSelectedTests([]);
    setShowingTestResults(false);
  }, [resetTrigger]);

  const fetchLiveTests = async (currentSearch) => {
    if (currentSearch === '') {
      setShowingTestResults(false);
      return;
    }
    setShowingTestResults(true);
    setIsFetchingTests(true);
    try {
      const response = await getLiveTestsAndPackages({
        skip: 0,
        limit: 3,
        query: currentSearch
      });
      const { tests } = response;
      const currentFetchedTests = tests.map((test) => ({
        test,
        isSelected: selectedTests.some((selected) => selected.id === test.id)
      }));
      setFetchedTests(currentFetchedTests);
    } catch (error) {
      message.error('Failed to search tests');
    }
    setIsFetchingTests(false);
  };

  const handleSelectTest = (test) => {
    const updatedSelectedTests = [...selectedTests, test];
    const updatedFetchedTests = fetchedTests.map((item) =>
      item.test.id === test.id ? { ...item, isSelected: true } : item
    );
    setSelectedTests(updatedSelectedTests);
    setFetchedTests(updatedFetchedTests);
    onSelectedTestsChange(updatedSelectedTests); // Notify parent about the selected tests
  };

  const handleRemoveTest = (testId) => {
    const updatedSelectedTests = selectedTests.filter(
      (test) => test.id !== testId
    );
    const updatedFetchedTests = fetchedTests.map((item) =>
      item.test.id === testId ? { ...item, isSelected: false } : item
    );
    setSelectedTests(updatedSelectedTests);
    setFetchedTests(updatedFetchedTests);
    onSelectedTestsChange(updatedSelectedTests); // Notify parent about the selected tests
  };

  return (
    <div>
      <div className="tests-modal-search">
        <SearchComponent
          name="Tests"
          debounceTime={DEBOUNCE_TIME}
          getData={fetchLiveTests}
        />
        {!isFetchingTests && showingTestResults && (
          <div className="search-result">
            {fetchedTests.length <= 0 ? (
              <Empty />
            ) : (
              fetchedTests.map(({ test, isSelected }) => (
                <div className="tests-list" key={test.id}>
                  <Typography.Text>{test.title}</Typography.Text>
                  {isSelected ? (
                    <Button
                      type="text"
                      onClick={() => handleRemoveTest(test.id)}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleSelectTest(test)}
                      type="default"
                    >
                      Add
                    </Button>
                  )}
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <div className="selected-test-container common-tests-container">
        {selectedTests.length <= 0 ? (
          <Empty description="No tests selected" />
        ) : (
          selectedTests.map(({ id, title }) => (
            <div className="tests-list" key={id}>
              <Typography.Text>{title}</Typography.Text>
              <Button type="text" onClick={() => handleRemoveTest(id)}>
                Remove
              </Button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default SearchTests;

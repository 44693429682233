/* eslint-disable no-nested-ternary */

import { Button, message, Space, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { AiOutlineShareAlt } from 'react-icons/ai';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import { GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import {
  getAllAffiliateOrganizations,
  updateAffiliateLinkAsAdmin
} from './affiliate-repository';

function Affiliate() {
  const limit = GLOBAL_PAGINATION_LIMIT;

  const [skipVal, setSkipVal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [affiliateData, setAffiliateData] = useState([]);
  const [paginationProp, setPaginationProp] = useState(false);

  const fetchLinkData = async ({ currentSkip }) => {
    try {
      setLoadingData(true);
      setLoading(true);

      const response = await getAllAffiliateOrganizations({
        skip: currentSkip,
        limit: limit
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      const { affiliates } = response;
      setAffiliateData(affiliates);
      setLoadingData(false);
      setLoading(false);
    } catch (error) {
      setLoadingData(false);
      setLoading(false);
      // message.error('Failed to fetch link data');
    }
  };

  useEffect(() => {
    fetchLinkData({
      currentSkip: skipVal
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyAffiliateLink = async (data) => {
    // eslint-disable-next-line no-console
    console.log({ data });
    const { affiliateLink } = data;
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(affiliateLink).then(() => {
      message.success('Link copied to clipboard');
    });
  };

  // COLUMNS
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => {
        const { isAffiliateGenerated } = record;
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px'
            }}
          >
            {!isAffiliateGenerated && (
              <Button
                style={{
                  display: 'flex',
                  border: 'none',
                  borderRadius: '4px',
                  backgroundColor: '#232e78',
                  color: 'white',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                loading={savingData}
                onClick={async () => {
                  try {
                    setSavingData(true);
                    await updateAffiliateLinkAsAdmin(value);
                    setSavingData(false);
                    message.success('Link generated');
                    fetchLinkData({
                      currentSkip: skipVal
                    });
                  } catch (error) {
                    message.error('Failed to generate link');
                  }
                }}
              >
                Generate
              </Button>
            )}
            {isAffiliateGenerated && (
              <Button
                onClick={() => {
                  copyAffiliateLink(record);
                }}
                style={{
                  display: 'flex',
                  borderRadius: '4px',
                  borderColor: '#232e78',
                  color: '#232e78',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AiOutlineShareAlt
                  size={20}
                  style={{
                    cursor: 'pointer'
                  }}
                />
              </Button>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Affiliate
            </Typography.Text>
          </Space>
        </div>
      </Header>
      <div className="main-content customers-content">
        <div className="customers-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp || false}
              data={affiliateData}
              onChange={(onChange) => {
                setSkipVal((onChange.current - 1) * limit);
                setLoadingData(true);
                fetchLinkData({
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default Affiliate;

import { gql } from '@apollo/client';

export const GET_ALL_AFFILIATE_ORGANIZATIONS = gql`
  query GetAffiliatesAsAdmin($filters: GetAffiliatesInput) {
    getAffiliatesAsAdmin(filters: $filters) {
      affiliates {
        id
        name
        email
        affiliateLink
        isAffiliateGenerated
      }
      total
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

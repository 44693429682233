import client from '../../apollo';
import {
  CREATE_UNIVERSAL_CART,
  DELETE_UNIVERSAL_CART
} from './graphql/mutation';
import { GET_ALL_UNIVERSAL_CARTS } from './graphql/query';

export const getAllUniversalCarts = async (filters) => {
  const { skip, limit } = filters;
  try {
    const response = await client.query({
      query: GET_ALL_UNIVERSAL_CARTS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit
        }
      }
    });
    return response?.data?.getAllUniversalCarts;
  } catch (error) {
    return [];
  }
};

export const createNewUniversalCart = async (data) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_UNIVERSAL_CART,
      fetchPolicy: 'network-only',
      variables: { data }
    });
    return response?.data?.createUniversalCart?.universalCart;
  } catch (error) {
    return false;
  }
};

export const deleteUniversalCart = async (deleteUniversalCartId) => {
  try {
    const response = await client.query({
      query: DELETE_UNIVERSAL_CART,
      fetchPolicy: 'network-only',
      variables: {
        deleteUniversalCartId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const sample = () => {};

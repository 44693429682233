/* eslint-disable no-undef */
/* eslint-disable no-unsafe-optional-chaining */
import { gql } from '@apollo/client';
import { message } from 'antd';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import XLSX from 'sheetjs-style';
import client from '../apollo';
import config from './config';
import {
  EQUIPMENTS_ID,
  EQUIPMENTS_ID_DEV,
  FILETYPE,
  FILE_EXTENSION,
  REGEX,
  SAMPLE_ID,
  SAMPLE_ID_DEV,
  defaultDateFormat
} from './constants';

export const GET_SIGNED_URL = gql`
  mutation getUserProfileSignedUrl($data: CommonFileInput!) {
    getUserProfileSignedUrl(data: $data) {
      fileName
      signedUrl
      getUrl
    }
  }
`;
// Portal related methods
export const injectUsingPortal = (portalId) => {
  // eslint-disable-next-line no-undef
  return document?.getElementById(portalId);
};

export const isPortalIdExists = (portalId) => {
  return !!injectUsingPortal(portalId);
};

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) => {
  // eslint-disable-next-line no-undef
  return document?.getElementById(portalId);
};

export const isDocumentIdExist = (portalId) => {
  return !!getElementFromDocumentId(portalId);
};
// Check for document Id's exists end

export const formatDate = (
  dateTime,
  format = `${defaultDateFormat} hh:mm A`
) => {
  if (dateTime && moment && format) {
    return moment(dateTime)?.format(format);
  }

  return dateTime;
};
export const withHttp = (url) =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url;

export const formValidatorRules = {
  required: {
    validator(rule, value) {
      const currentValue = value || '';
      if (currentValue.toString().trim() === '') {
        return Promise.reject(new Error('Required'));
      }
      return Promise.resolve();
    }
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.setRequestHeader('x-amz-acl', 'public-read');
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    message?.error(error?.message);
  }
}

export const getSignedUrl = async (fileObj) => {
  const fileName = fileObj?.name;

  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'write',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key
      }
    }
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const uploadImage = async (signedRequest, fileObj) => {
  const api = axios?.create({
    baseURL: 'url'
  });
  await api(signedRequest, {
    method: 'PUT',
    data: fileObj?.originFileObj || fileObj,
    headers: {
      'Content-Type': fileObj?.type
    }
  });
};

export const fetchImage = async (fileObj) => {
  const fileName = fileObj?.name;
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'read',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key
      }
    }
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const fullDateFormat = (dateTime) => {
  const day = dateTime.getDate();

  const monthIndex = dateTime.getMonth();
  const monthName = monthNames[monthIndex];

  const year = dateTime.getFullYear();

  return `${day} ${monthName}, ${year}`;
};

const fileType = FILETYPE;
const fileExtension = FILE_EXTENSION;

export const exportToExcel = async ({ excelData, fileName }) => {
  const ws = XLSX.utils.json_to_sheet(excelData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  // eslint-disable-next-line no-undef
  const data = new Blob([excelBuffer], { type: fileType });
  // FileSaver.saveAs(data, fileName + fileExtension);
  return FileSaver.saveAs(data, fileName + fileExtension);
};

export const clearSearchField = (name) => {
  // eslint-disable-next-line no-undef
  document
    .getElementsByName(name)[0]
    .parentNode.lastChild.lastChild.lastChild.click();
};

export const getSampleData = (value) => {
  if (!value) {
    message.error('Excel sheet has empty spaces');
    return;
  }

  const separatedValue = value.split(',');
  const dataArr = [];
  const sampleID = config.ENV === 'development' ? SAMPLE_ID_DEV : SAMPLE_ID;
  for (let i = 0; i < separatedValue.length; i += 1) {
    if (separatedValue[i] === 'B') {
      dataArr.push(sampleID.BLOOD);
    }
    if (separatedValue[i] === 'U') {
      dataArr.push(sampleID.URINE);
    }
    if (separatedValue[i] === 'S') {
      dataArr.push(sampleID.STOOL);
    }
  }
  return dataArr;
};

export const getTotalPrice = (kind, price, discount) => {
  if (kind === 'FLAT') {
    const totalPrice = price - discount;
    return parseFloat(totalPrice);
  }
  const discountPrice = (discount / 100) * price;
  const totalPrice = parseFloat(price - discountPrice).toFixed(2);
  return parseFloat(totalPrice);
};

export const equipmentsRequiredInBulk = (equipmentArr) => {
  const separatedEquipment = equipmentArr.split(',');
  const newEqpArr = [];
  const equipmentID =
    config.ENV === 'development' ? EQUIPMENTS_ID_DEV : EQUIPMENTS_ID;

  for (let e = 0; e < separatedEquipment.length; e += 1) {
    const newArrSeparatedByDash = separatedEquipment[e].split('-');
    let equipment;
    if (newArrSeparatedByDash[0] === 'A') {
      equipment = equipmentID.EDTA_TUBE;
    } else if (newArrSeparatedByDash[0] === 'B') {
      equipment = equipmentID.PLANE_TUBE;
    } else if (newArrSeparatedByDash[0] === 'C') {
      equipment = equipmentID.FLUORIDE_TUBE;
    } else if (newArrSeparatedByDash[0] === 'D') {
      equipment = equipmentID.CITRATE_TUBE;
    } else if (newArrSeparatedByDash[0] === 'E') {
      equipment = equipmentID.HEPARIN;
    } else if (newArrSeparatedByDash[0] === 'F') {
      equipment = equipmentID.URINE_CONTAINER;
    } else if (newArrSeparatedByDash[0] === 'G') {
      equipment = equipmentID.SYRING_2ML;
    } else if (newArrSeparatedByDash[0] === 'H') {
      equipment = equipmentID.SYRING_5ML;
    } else if (newArrSeparatedByDash[0] === 'I') {
      equipment = equipmentID.SYRING_10ML;
    } else if (newArrSeparatedByDash[0] === 'J') {
      equipment = equipmentID.GLOVES;
    }
    const eqpData = {
      equipment,
      requiredQuantity: parseInt(newArrSeparatedByDash[1], 10)
    };
    newEqpArr.push(eqpData);
  }
  return newEqpArr;
};

// TO CALCULATE WEEK START AND END DATE
export const getStartAndEndDateFromWeek = (year, weekNumber) => {
  // Create a new Date object for January 1st of the specified year
  const januaryFirst = new Date(year, 0, 1);

  // Calculate the first day of the week (Sunday) of the specified weekNumber
  const firstDayOfWeek = new Date(
    januaryFirst.getTime() + (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
  );

  // Calculate the last day of the week (Saturday) by adding 6 days to the first day of the week
  const lastDayOfWeek = new Date(
    firstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000
  );
  // eslint-disable-next-line no-console
  return {
    startDate: firstDayOfWeek.toDateString(),
    endDate: lastDayOfWeek.toDateString()
  };
};

// TO CALCULATE IST DATE
export const toLocalISOString = (date) => {
  const offset = date.getTimezoneOffset() * 60000; // Convert offset to milliseconds
  const localISOTime = new Date(date - offset).toISOString().slice(0, -1);
  return localISOTime;
};

export function convertPercentageToOriginalPrice(price, discount) {
  return price / (1 - discount / 100);
}

export const getMonthDates = (value) => {
  const selectedDate = new Date(value);

  // Set the start date to the 1st day of the selected month
  const startDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
  );
  // Calculate the end of the month
  const endOfMonth = new Date(startDate);
  endOfMonth.setMonth(startDate.getMonth() + 1, 0); // Set the day to 0 of the next month (last day of the current month)

  // Check if the year is a leap year
  const year = startDate.getFullYear();
  const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

  if (isLeapYear) {
    // Modify endOfMonth for leap years (February has 29 days)
    if (startDate.getMonth() === 1) {
      // February (0-based index)
      endOfMonth.setDate(29);
    }
  } else {
    //
  }

  // Set your values
  const startDateValue = toLocalISOString(startDate);
  const endDateValue = toLocalISOString(endOfMonth);
  return { startDateValue, endDateValue };
};

export const downloadAsExcel = (dataArray) => {
  const worksheet = XLSX.utils.json_to_sheet(dataArray);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  });

  const data = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'data.xlsx');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// VALIDATING IMG AS SVG
export const beforeUpload = (file) => {
  const isSvg = file.type === 'image/svg+xml';
  const isLT10MB = file.size / 1024 / 1024 <= 10;
  if (!isLT10MB) {
    message.error('Image must smaller than 10MB!');
    return !isLT10MB && Upload.LIST_IGNORE;
  }
  if (!isSvg) {
    message.error(`${file?.name} is not a svg file`);
  }
  return false;
};

export const generateSlug = (slugName) => {
  return slugName
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '');
};

export const downloadFile = async (url, fileName) => {
  try {
    const response = await fetch(url); // Fetch the file content
    if (!response.ok) throw new Error('Failed to fetch the file');

    const blob = await response.blob(); // Convert the content to a Blob
    const blobUrl = URL.createObjectURL(blob); // Create a temporary URL for the Blob

    // Create a link element to trigger the download
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileName; // Name of the file when it's downloaded
    a.click(); // Trigger the download

    // Clean up the Blob URL after download
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    message.error('Failed to download card image');
  }
};

// Helper function to fetch an image and return as a Blob
export const fetchImageBlob = async (url) => {
  const response = await fetch(url);
  if (!response.ok) throw new Error('Failed to fetch the image');
  // eslint-disable-next-line no-return-await
  return await response.blob();
};

export const testTitleEllipsis = (testName, valueAmount) => {
  const title =
    testName.length > valueAmount
      ? `${testName.substring(0, valueAmount)}...`
      : testName;
  return title;
};

/* eslint-disable no-nested-ternary */
import { DatePicker, message, Select, Space, Tabs, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CalenderIcon } from '../../assets/calendar.svg';
import SearchComponent from '../../common/components/SearchDebounce';
import {
  DEBOUNCE_TIME,
  ORDER_TEMPLATE_CONSTANTS,
  WHATSAPP_TEMPLATE_SELECT
} from '../../common/constants';
import { getStartAndEndDateFromWeek } from '../../common/utils';
import { getDoctorsList } from '../doctors-tab/doctor-repository';
import { getAllWhatsappTemplates } from '../whatsapp-templates/whatsapp-templates-reporitory';
import ActiveReports from './components/ActiveReports';
import CancelledOrders from './components/CancelledOrders';
import HistoryReports from './components/HistoryReports';
import './reports.less';

function Reports() {
  const [query, setQuery] = useState('');
  const [type, setType] = useState('all');
  const [startDateVal, setStartDateVal] = useState(null);
  const [endDateVal, setEndDateVal] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [templates, setTemplates] = useState([]);
  const { reportType } = useParams();
  const navigate = useNavigate();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const fetchDoctorList = async () => {
    try {
      const response = await getDoctorsList({
        skip: 0,
        limit: 1000,
        query: ''
      });
      setDoctorList(response.doctors);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching doctors list:', error);
    }
  };

  const fetchCartTemplates = async () => {
    const filters = {
      limit: 1000000,
      skip: 0
    };
    const payload = {
      type: WHATSAPP_TEMPLATE_SELECT.UTILITY,
      filters
    };
    try {
      const response = await getAllWhatsappTemplates(payload);
      if (response) {
        const { templates: tempResponse } = response;
        // eslint-disable-next-line no-console
        console.log({ tempResponse });
        const newTemplates = tempResponse.filter((template) =>
          ORDER_TEMPLATE_CONSTANTS.includes(template.templateId)
        );
        // eslint-disable-next-line no-console
        console.log({ newTemplates });
        setTemplates(newTemplates);
      }
    } catch (error) {
      message.error('Failed to fetch templates');
    }
  };

  useEffect(() => {
    fetchDoctorList();
    fetchCartTemplates();
  }, []);

  // Determine the default active tab based on reportType
  const defaultActiveTabKey =
    reportType === 'history' ? '2' : reportType === 'cancelled' ? '3' : '1';

  const handleTabChange = (activeTabKey) => {
    setStartDateVal(null);
    setEndDateVal(null);
    setType('all');
    const newReportType =
      activeTabKey === '2'
        ? 'history'
        : activeTabKey === '3'
        ? 'cancelled'
        : 'active';
    navigate(`/reports/${newReportType}/1`);
  };

  const dateSelectionType = () => {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const istTime = dayjs(value).format('YYYY-MM-DD');
              setStartDateVal(istTime);
              setEndDateVal(istTime);
            }}
          />
        );
      case 'week':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(date, dateString) => {
              const { startDate, endDate } =
                getStartAndEndDateFromWeek(dateString);
              setStartDateVal(dayjs(startDate).format('YYYY-MM-DD'));
              setEndDateVal(dayjs(endDate).format('YYYY-MM-DD'));
            }}
          />
        );
      case 'month':
        return (
          <DatePicker
            suffixIcon={<CalenderIcon />}
            picker={type}
            onChange={(value) => {
              const startDate = dayjs(value)
                .startOf('month')
                .format('YYYY-MM-DD');
              const endDate = dayjs(value).endOf('month').format('YYYY-MM-DD');
              setStartDateVal(startDate);
              setEndDateVal(endDate);
            }}
          />
        );
      case 'custom':
        return (
          <RangePicker
            suffixIcon={<CalenderIcon />}
            onChange={(value) => {
              if (value && value.length === 2) {
                setStartDateVal(dayjs(value[0]).format('YYYY-MM-DD'));
                setEndDateVal(dayjs(value[1]).format('YYYY-MM-DD'));
              } else {
                setStartDateVal(undefined);
                setEndDateVal(undefined);
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Reports booked
            </Typography.Text>
            <SearchComponent
              name="Reports"
              debounceTime={DEBOUNCE_TIME}
              getData={(change) => {
                setQuery(change);
              }}
            />
          </Space>
        </div>
      </Header>
      <div className="content content-class">
        <div className="tabs-header-reports">
          <Tabs
            onTabClick={handleTabChange}
            defaultActiveKey={defaultActiveTabKey}
            items={[
              {
                key: '1',
                label: `Active`,
                children: (
                  <ActiveReports
                    query={query}
                    startDateVal={startDateVal}
                    endDateVal={endDateVal}
                    doctorList={doctorList}
                    templates={templates}
                  />
                )
              },
              {
                key: '2',
                label: `History`,
                children: (
                  <HistoryReports
                    query={query}
                    startDateVal={startDateVal}
                    endDateVal={endDateVal}
                    doctorList={doctorList}
                    templates={templates}
                  />
                )
              },
              {
                key: '3',
                label: `Cancelled Orders`,
                children: (
                  <CancelledOrders
                    query={query}
                    startDateVal={startDateVal}
                    endDateVal={endDateVal}
                    doctorList={doctorList}
                    templates={templates}
                  />
                )
              }
            ]}
          />
        </div>
        <div className="select-date-time selector-class">
          <Select
            className="day-selector"
            value={type}
            onChange={(value) => {
              setType(value);
              if (value === 'all') {
                setStartDateVal(null);
                setEndDateVal(null);
              }
            }}
          >
            <Option value="all">All</Option>
            <Option value="date">Date</Option>
            <Option value="week">Week</Option>
            <Option value="month">Month</Option>
            <Option value="custom">Custom</Option>
          </Select>
          {dateSelectionType()}
        </div>
      </div>
    </div>
  );
}

export default Reports;

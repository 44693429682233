import client from '../../apollo';
import { UPDATE_AFFILIATE_LINK } from './graphql/mutation';
import { GET_ALL_AFFILIATE_ORGANIZATIONS } from './graphql/query';

export const getAllAffiliateOrganizations = async (filters) => {
  const { skip, limit, query } = filters;

  try {
    const response = await client.query({
      query: GET_ALL_AFFILIATE_ORGANIZATIONS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query
        }
      }
    });
    return response?.data?.getAffiliatesAsAdmin;
  } catch (error) {
    return [];
  }
};

export const updateAffiliateLinkAsAdmin = async (affiliateId) => {
  try {
    const response = await client.query({
      query: UPDATE_AFFILIATE_LINK,
      fetchPolicy: 'network-only',
      variables: {
        affiliateId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};
export const test = async () => {};

import { gql } from '@apollo/client';

export const CREATE_UNIVERSAL_CART = gql`
  mutation CreateUniversalCart($data: UniversalCartInput) {
    createUniversalCart(data: $data) {
      universalCart {
        id
        cartName
        isDeleted
        tests {
          id
          title
        }
        placed
        claimed
        dynamicLink
      }
    }
  }
`;

export const DELETE_UNIVERSAL_CART = gql`
  mutation DeleteUniversalCart($deleteUniversalCartId: String!) {
    deleteUniversalCart(id: $deleteUniversalCartId)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;

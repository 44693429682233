import { gql } from '@apollo/client';

export const GET_ALL_UNIVERSAL_CARTS = gql`
  query getAllUniversalCarts($filters: PaginationInput!) {
    getAllUniversalCarts(filters: $filters) {
      universalCart {
        id
        cartName
        isDeleted
        tests {
          id
          title
        }
        placed
        claimed
        dynamicLink
      }
      total
    }
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
